module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_twuu2u67yosvyangmahz42aofa/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TypeScript Documentation","short_name":"TS Docs","start_url":"/","background_color":"white","theme_color":"#3178C6","display":"standalone","icon":"static/icons/ts-logo-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8944a05a8b601855de116c8a56d3b3ae"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-remark-images@7.13.1_gatsby-plugin-sharp@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_esli_e667jem2cpcyfno7oag36q5rse/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__react-_fba6s3ljgwvumnuu6ts3ewlmfy/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_pegr7i6nislyuh2zytvd77orsu/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(sandbox|play|dev)"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__tlmo3tlvagsfnfr2xounm42b3e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
